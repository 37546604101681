import { Injectable } from '@angular/core';
import { CurrentUserPreferenceStore } from '../../../../core/store/current-user-preference.store';
import {
  UserDetailsDTO,
  UserFormDTO,
  UserListDTO,
  UserMinimalDTO,
} from '../dto/user.dto';
import { MainConversionInterface } from '../../../../core/interfaces/conversion/main-conversion.interface';
import { FormGroup } from '@angular/forms';
import { TableConversion } from '../../../../shared/conversion/table.conversion';
import { convertResponseToUserStatusDetailsDTO } from '../../../settings/organization/dto/user-status.dto';
import { convertResponseToDesignationDetailsDTO } from '../../../settings/organization/dto/designation.dto';
import {
  RoleDTO,
} from '../../../settings/general-settings/dto/role.dto';
import { AppSettingsStore } from '../../../../core/store/app-settings.store';
import { RoleConversion } from '../../../settings/general-settings/conversion/role.conversion';

@Injectable({
  providedIn: 'root',
})
export class UserConversion
  implements MainConversionInterface<UserListDTO, UserFormDTO, UserDetailsDTO>
{
  private lang: string;

  constructor(
    private currentUserPreferenceStore: CurrentUserPreferenceStore,
    private tableConv: TableConversion,
    private appSettingsStore: AppSettingsStore,
    private roleConv: RoleConversion
  ) {
    this.lang = this.currentUserPreferenceStore.languageKey;
  }

  resToList(response: any[]): UserListDTO[] {
    if (!response) {
      return [];
    }
    const result = response.map((item) => {
      return {
        id: item.id,
        user: this.resObjToUserMinimalDTO(item),
        name: item[`first_name_${this.lang}`] + item[`last_name_${this.lang}`],
        initial: item.initial,
        email: item.email,
        designation_title: item[`designation_title_${this.lang}`],
        color: item.color,
        mobile_number: item.mobile_number,
        roles: item[`roles_${this.lang}`],
        user_status: this.tableConv.resToColordItem(item, 'user_status'),
      };
    });
    return result;
  }

  formGroupToForm(formGroup: FormGroup): UserFormDTO {
    return {
      id: formGroup.get('id')?.value,
      organization_unit_1_id: formGroup.get('OrganizationLevel1')?.value,
      organization_unit_2_id: formGroup.get('OrganizationLevel2')?.value,
      organization_unit_3_id: formGroup.get('OrganizationLevel3')?.value,
      organization_unit_4_id: formGroup.get('OrganizationLevel4')?.value,
      organization_unit_5_id: formGroup.get('OrganizationLevel5')?.value,
      designation_id: formGroup.get('Designation')?.value,
      first_name_en: formGroup.get('FirstName')?.value,
      last_name_en: formGroup.get('LastName')?.value,
      first_name_ar: formGroup.get('FirstName')?.value,
      last_name_ar: formGroup.get('LastName')?.value,
      email: formGroup.get('Email')?.value,
      mobile_number: formGroup.get('Mobile')?.value,
      role_ids: formGroup.get('Roles')?.value,
      user_status_id: formGroup.get('UserStatus')?.value,
    };
  }

  resToForm(response: any) {
    return {
      id: response.id,
      FirstName: response.first_name_en,
      LastName: response.last_name_en,
      Email: response.email,
      Mobile: response.mobile_number,
      Roles: response.roles?.map((roles: { id: number }) => roles.id) || [],
      Designation: response.designation?.id,
      UserStatus: response.user_status?.id,
      OrganizationLevel1: response.organization_unit_1.id, //todo - to be fixed
      OrganizationLevel2: response.organization_unit_2?.id,
      OrganizationLevel3: response.organization_unit_3?.id,
      OrganizationLevel4: response.organization_unit_4?.id,
      OrganizationLevel5: response.organization_unit_5?.id,
    };
  }

  resToDetails(response: any): UserDetailsDTO {
    return {
      id: response.id,
      first_name: response[`first_name_${this.lang}`],
      last_name: response[`last_name_${this.lang}`],
      email: response.email,
      mobile_number: response.mobile_number,
      last_login_at: response.last_login_at,
      profile_image_path: response.profile_image_path,
      initial: response.initial,
      color: response.color,
      created_at: response.created_at,
      updated_at: response.updated_at,
      organization_unit_1: response.organization_unit_1,
      organization_unit_2: response.organization_unit_2,
      organization_unit_3: response.organization_unit_3,
      organization_unit_4: response.organization_unit_4,
      organization_unit_5: response.organization_unit_5,
      user_status: convertResponseToUserStatusDetailsDTO(
        response.user_status,
        this.lang
      ),
      designation: convertResponseToDesignationDetailsDTO(
        response.designation,
        this.lang
      ),
      roles: response.roles.map((role: RoleDTO) =>
        this.roleConv.resToDetails(role)
      ),
      created_by_user: this.resObjToUserMinimalDTO(response.created_by_user),
      updated_by_user: this.resObjToUserMinimalDTO(response.updated_by),
    };
  }

  resToUserMinimalDTO(response: any, key: string): UserMinimalDTO | null {
    if (!response) {
      return null;
    }
    return {
      id: response.id,
      first_name: response[`${key}_first_name_${this.lang}`],
      last_name: response[`${key}_last_name_${this.lang}`],
      profile_image_path: response[`${key}_profile_image_path`],
      initial: response[`${key}_initial`],
      color: response[`${key}_color_code`],
    };
  }

  resToUserMinimalIdFixedDTO(
    response: any,
    key: string
  ): UserMinimalDTO | null {
    if (!response) {
      return null;
    }
    return {
      id: response[`${key}_id`],
      first_name: response[`${key}_first_name_${this.lang}`],
      last_name: response[`${key}_last_name_${this.lang}`],
      profile_image_path: response[`${key}_profile_image_path`],
      initial: response[`${key}_initial`],
      color: response[`${key}_color_code`],
    };
  }

  resObjToUserMinimalDTO(response: any): UserMinimalDTO | null {
    if (!response) {
      return null;
    }
    return {
      id: response.id,
      first_name: response[`first_name_${this.lang}`],
      last_name: response[`last_name_${this.lang}`],
      profile_image_path: response[`profile_image_path`],
      initial: response[`initial`],
      color: response[`color_code`],
    };
  }
}
